<template>
  <modal-comp
    :title-text="state.isUpdating ? 'Update Offer' : 'Add New Offer'"
    :show="state.showAction"
    @hide="fireEvent('hide')"
    @submit="handleDataSave()"
    title-icon-class="me-1 icon-price-tag"
    :loading="state.formSubmitting"
  >
    <div class="row">
      <!--offer type-->
      <div class="form-group col-6">
        <label class="col-form-label">
          Create Coupon/Discount:
          <form-input-help>Disable to create a discount</form-input-help>
        </label>
        <div>
          <switch-comp
            :checked="state.form.typeCoupon"
            @change="val=>state.form.typeCoupon=val"
            enable-text="Creating Coupon"
            disable-text="Creating Discount"
          />
        </div>
      </div>

      <!--coupon code-->
      <FadeSlideAnimation
        duration="2"
        direction-forward
      >
        <div
          class="form-group col-6"
          v-if="state.form.typeCoupon"
        >
          <label for="coupon-code" class="col-form-label">
            Coupon Code:
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="coupon-code"
            placeholder="Coupon Code"
            v-model.trim="state.form.code.value"
            autocomplete="off"
            required
          >
          <FormErrorMsg :error="state.form.code.error" />
        </div>
      </FadeSlideAnimation>

      <!--price-->
      <div class="form-group col-6">
        <label for="price" class="col-form-label">
          Price:
          <span class="text-danger">*</span>
          <form-input-help>Price with decimals</form-input-help>
        </label>
        <input
          type="number"
          step="0.01"
          class="form-control"
          id="price"
          placeholder="Price"
          v-model.trim="state.form.amount.value"
          autocomplete="off"
          required
        >
        <FormErrorMsg :error="state.form.amount.error" />
      </div>

      <!--price type-->
      <div class="form-group col-6">
        <label class="col-form-label">
          Price Type:
          <form-input-help>Fixed or percentage</form-input-help>
        </label>
        <div>
          <switch-comp
            :checked="state.form.fixed"
            @change="val=>state.form.fixed=val"
            enable-text="Fixed"
            disable-text="Percentage"
          />
        </div>
      </div>

      <!--first time-->
      <FadeSlideAnimation
        duration="2"
        direction-forward
      >
        <div
          v-if="state.form.typeCoupon"
          class="form-group col-6"
        >
          <label class="col-form-label">
           Available For:
<!--            <form-input-help>Enable to make this only valid for 1st order per user</form-input-help>-->
          </label>
          <div>
            <tree-select
              id="order-type"
              placeholder="Select"
              v-model="state.form.availableFor"
              :options="state.options.availableFor"
              :multiple="false"
              :searchable="false"
              :clearable="false"
              :disabled="(state.form.availableFor === AVAILABLE_FOR_NEW) && state.form.once && state.isUpdating"
              value="node"
            />
          </div>
        </div>
      </FadeSlideAnimation>

      <!--onetime-->
      <FadeSlideAnimation
        duration="2"
        direction-forward
      >
        <div
          v-if="state.form.typeCoupon"
          class="form-group col-6"
        >
          <label class="col-form-label">
            For Once:
            <form-input-help>Enable to make this valid for only once per user</form-input-help>
          </label>
          <div>
            <switch-comp
              :checked="state.form.once"
              :disabled="state.form.availableFor === AVAILABLE_FOR_NEW && !state.isUpdating"
              @change="val=>state.form.once=val"
              enable-text="For Once"
              disable-text="For All"
            />
          </div>
        </div>
      </FadeSlideAnimation>

      <!--order type-->
      <div class="form-group col-6">
        <label class="col-form-label">
          Order Type:
        </label>
        <tree-select
          id="order-type"
          placeholder="Select order type"
          v-model="state.form.orderType.value"
          :options="state.options.order"
          :multiple="false"
          :searchable="false"
          :clearable="false"
          value="node"
        />
        <FormErrorMsg :error="state.form.orderType.error" />
      </div>

      <!--minimum order-->
      <div
        :class="`form-group col-${state.form.typeCoupon ? '6':'12'}`"
      >
        <label for="min-order" class="col-form-label">
          Minimum Order:
          <span class="text-danger">*</span>
          <form-input-help>Minimum order amount to avail the {{
              state.form.typeCoupon ? 'coupon' : 'discount'
            }}
          </form-input-help>
        </label>
        <input
          type="number"
          step="0.01"
          class="form-control"
          id="min-order"
          placeholder="Minimum order amount"
          v-model.trim="state.form.minOrder.value"
          autocomplete="off"
          required
        >
        <FormErrorMsg :error="state.form.minOrder.error" />
      </div>

      <!--  applicable status-->
      <FadeSlideAnimation
        duration="2"
        direction-forward
      >
      <div
        class="form-group col-12"
        v-if="!state.form.typeCoupon">

        <label class="col-form-label">
          Applicable with Coupon?
          <form-input-help>
            When enabled users can apply coupons while having this discount else applying coupon will remove this
          </form-input-help>
        </label>
        <div>
          <switch-comp
            :checked="state.form.discountApplicable"
            @change="val=>state.form.discountApplicable=val"
            enable-text="Apply With Coupons"
            disable-text="Do not apply with Coupon"
          />
        </div>
      </div>

      </FadeSlideAnimation>

      <!--   Pick Date   -->
      <div class="form-group col-12">
        <label class="col-form-label">
          Select {{ state.form.typeCoupon ? 'Coupon' : 'Discount' }} Period:
          <form-input-help>
            Select dates between when the {{ state.form.typeCoupon ? 'coupon' : 'discount' }} will be valid for
          </form-input-help>
        </label>

        <DatePicker
          v-model="state.form.dates"
          is-range
          :timezone="datePickerTimeZone"
        >
          <template v-slot="{inputValue, togglePopover}">

            <div
              class="form-group has-search"
              @click="togglePopover"
            >
              <span class="icomoon icon-calendar form-control-feedback" />

              <input
                type="text" class="form-control"
                placeholder="Search"
                :value="`${inputValue.start} - ${inputValue.end}`"
                readonly
              >
            </div>

          </template>
        </DatePicker>
        <FormErrorMsg :error="state.form.datesError.start || state.form.datesError.end" />
      </div>

      <!--sort-->
      <div class="form-group col-4">
        <label for="sort" class="col-form-label">
          Sort:
          <span class="text-danger">*</span>
        </label>
        <form-input-help>Works on descending order</form-input-help>
        <input
          type="number"
          class="form-control"
          id="sort"
          placeholder="Sort position (descending)"
          v-model.trim="state.form.sort.value"
          autocomplete="off"
          required
        />
        <FormErrorMsg :error="state.form.sort.error" />
      </div>

      <!--show/hide-->
      <div class="form-group col-4">
        <label class="col-form-label">
          Show / Hide:
          <form-input-help>Disabling will hide this but can be used</form-input-help>
        </label>
        <div>
          <switch-comp
            :checked="state.form.show"
            @change="val=>state.form.show=val"
            enable-text="Shown"
            disable-text="Hidden"
          />
        </div>
      </div>

      <!--status-->
      <div class="form-group col-4">
        <label class="col-form-label">
          Status:
          <form-input-help> Disabling will make this unusable</form-input-help>
        </label>
        <div>
          <switch-comp
            :checked="state.form.status"
            @change="val=>state.form.status=val"
            enable-text="Enabled"
            disable-text="Disabled"
          />
        </div>
      </div>
    </div>
  </modal-comp>
</template>

<script>
import ModalComp from '@/components/Util/ModalComp';
import { computed, defineComponent, reactive, watch } from 'vue';
import FormInputHelp from '@/components/Util/FormInputHelp';
import SwitchComp from '@/components/Util/SwitchComp';
import TreeSelect from '@tkmam1x/vue3-treeselect';
import { useStore } from 'vuex';
import { AVAILABLE_FOR_NEW, AVAILABLE_FOR_EXISTING, AVAILABLE_FOR_ALL } from '@/utils/constants';
import { DatePicker } from 'v-calendar';
import { formatDate, getDate, isEmptyObject } from '@/utils/Helper';
import Toaster from '@/utils/Toaster';
import { decimal, maxLength, minLength, minValue, required, } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import ErrorHelper from '@/utils/ErrorHelper';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import FadeSlideAnimation from '@/components/Util/Animations/FadeSlideAnimation';

export default defineComponent({
  name: 'OfferAction',
  emits: ['updated', 'hide'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    itemToUpdate: {
      type: Object,
      default: () => {
      }
    }
  },

  components: { FadeSlideAnimation, FormErrorMsg, SwitchComp, FormInputHelp, ModalComp, TreeSelect, DatePicker },
  setup (props, { emit }) {

    const store = useStore();

    const datePickerTimeZone = process.env.VUE_APP_SITE_TIMEZONE;

    const state = reactive({

      showAction: false,
      isUpdating: false,

      form: {
        id: null,
        typeCoupon: false,
        code: {
          error: false,
          value: ''
        },
        amount: {
          error: false,
          value: 0
        },
        fixed: false,
        availableFor: '',
        discountApplicable: false,
        once: false,
        orderType: {
          error: false,
          value: 'both'
        },
        minOrder: {
          error: false,
          value: 0
        },
        dates: {
          start: getDate(),
          end: getDate()
        },
        datesError: {
          start: false,
          end: false
        },
        sort: {
          error: false,
          value: 0
        },
        show: true,
        status: true,
      },

      options: {
        order: computed(() => store.getters['offer/orderTypes']),
        // payment: [],
        availableFor: [
          {
            id: 'New Customers',
            label: 'New Customers'
          },
          {
            id: 'Existing Customers',
            label: 'Existing Customers'
          },
          {
            id: 'All Customers',
            label: 'All Customers'
          },
        ],
      },

      formSubmitting: false

    });

    // validation start
    const validationRules = {
      form: {
        code: {
          value: {
            required: state.form.typeCoupon,
            minLength: minLength(5),
            maxLength: maxLength(20),
          }
        },
        amount: {
          value: {
            required,
            decimal,
            minValue: minValue(0)
          }
        },
        minOrder: {
          value: {
            required,
            decimal,
            minValue: minValue(0)
          }
        },
        sort: {
          value: {
            required,
            minValue: minValue(0)
          }
        },
      }
    };

    const validator = useVuelidate(validationRules, state, { $autoDirty: true });

    watch(validator, () => {
      // map errors
      ErrorHelper.getValidationErrors(validator, (errors) => {
          state.form.code.error = errors['form.code.value'] || false;
          state.form.amount.error = errors['form.amount.value'] || false;
          state.form.minOrder.error = errors['form.minOrder.value'] || false;
          state.form.datesError.start = errors['form.dates.start'] || false;
          state.form.datesError.end = errors['form.dates.end'] || false;
          state.form.sort.error = errors['form.sort.value'] || false;
        },
        {
          'form.code.value': 'Coupon Code',
          'form.amount.value': 'Price',
          'form.minOrder.value': 'Minimum Order',
          'form.dates.start': 'Start Date',
          'form.dates.end': 'End Date',
          'form.sort.value': 'Sort',
        }
      );

    });
    // validation end

    const populateNewForm = () => ({
      id: null,
      typeCoupon: false,
      code: {
        error: false,
        value: ''
      },
      amount: {
        error: false,
        value: 0
      },
      fixed: false,
      availableFor: AVAILABLE_FOR_NEW,
      discountApplicable: false,
      once: false,
      orderType: {
        error: false,
        value: 'both'
      },
      minOrder: {
        error: false,
        value: 0
      },
      dates: {
        start: getDate(),
        end: getDate()
      },
      datesError: {
        start: false,
        end: false
      },
      sort: {
        error: false,
        value: 0
      },
      show: true,
      status: true,
    });

    const handleDataSave = () => {

      const data = {
        amount: state.form.amount.value,
        code: state.form.typeCoupon ? state.form.code.value : '',
        dates: {
          start: formatDate(state.form.dates.start, 'yyyy-M-d'), // DateFns Date Format
          end: formatDate(state.form.dates.end, 'yyyy-M-d') // DteFns Date Format
        },
        discountApplicable: state.form.discountApplicable,
        availableFor: state.form.availableFor,
        fixed: state.form.fixed,
        minOrder: state.form.minOrder.value,
        once: state.form.availableFor === AVAILABLE_FOR_NEW ? true : state.form.once,
        orderType: state.form.orderType.value,
        show: state.form.show,
        sort: state.form.sort.value,
        status: state.form.status,
        type: state.form.typeCoupon ? 'coupon' : 'discount',
      };

      createOrUpdateOffer(data);
    };

    const createOrUpdateOffer = async (data) => {

      state.formSubmitting = true;

      try {

        let response = state.isUpdating
          ? await store.dispatch('offer/UpdateOffer', { data: data, id: state.form.id })
          : await store.dispatch('offer/createOffer', data);

        Toaster.successAlt({
          message: response.data.message || 'Offer successfully saved'
        });

        fireEvent('updated');

      } catch (e) {

        Toaster.error({
          message: e.message || 'Something went wrong. try again'
        });

        ErrorHelper.mapServerError(e, (err, ex) => {
          state.form.code.error = ex(err.code);
          state.form.amount.error = ex(err.amount);
          state.form.minOrder.error = ex(err.minOrder);
          state.form.orderType.error = ex(err.orderType);
          state.form.datesError.start = ex(err['dates.start']);
          state.form.datesError.end = ex(err['dates.end']);
          state.form.sort.error = ex(err.sort);
        });

      }

      state.formSubmitting = false;
    };

    const fireEvent = (event) => emit(event);

    const populateUpdateData = (nv) => {

      if (isEmptyObject(nv)) return;

      let offerData = {
        id: nv.id,
        amount: {
          error: false,
          value: nv.amount
        },
        code: {
          error: false,
          value: nv.code || ''
        },

        dates: {
          start: new Date(nv.from),
          end: new Date(nv.to)
        },

        datesError: {
          start: false,
          end: false,
        },

        availableFor: nv.coupon_available_for,
        discountApplicable: nv.discount_applicable,
        fixed: nv.fixed,
        minOrder: {
          error: false,
          value: nv.min_order
        },
        once: nv.once,
        orderType: {
          error: false,
          value: nv.for
        },
        show: nv.show,
        status: nv.status,
        sort: {
          error: false,
          value: nv.sort || 0
        },
        typeCoupon: nv.type === 'coupon'
      };

      state.form = offerData;
    };

    watch(() => props.show, (nv) => state.showAction = nv);

    watch(() => props.itemToUpdate, (nv) => {

      if (isEmptyObject(nv)) {
        state.form = populateNewForm();
        state.isUpdating = false;
        return;
      }

      state.isUpdating = true;
      populateUpdateData(nv);
    });

    return {
      state,
      AVAILABLE_FOR_NEW,
      fireEvent,
      handleDataSave,
      datePickerTimeZone,
    };
  },
});
</script>

<style scoped lang="scss">
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

input[readonly] {
  outline: none;
  background-color: transparent;
}
</style>
