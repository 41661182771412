<template>
  <ModalComp
    title-text="Update Promotional Banner"
    :show="state.showAction"
    mode="update"
    @hide="handleToggle()"
    @submit="handleDataSave()"
    title-icon-class="me-1 icon-price-tag"
    :loading="state.formSubmitting"
  >
    <div class="row">

      <!--promo banner-->
      <div class="form-group col-12">
        <label class="col-form-label">
          Image: <span class="text-danger">*</span>
          <span class="text-info small">Max file Size 1.5 MB</span>
        </label>

        <div
          class="form-group"
        >
          <div class="custom-file">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Upload Promotional Image"
                readonly
                :value="state.form.image.value?.name || state.form.image.value"
                @click="() => fileInputRef.click()"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-primary"
                  type="button"
                  id="button-addon2"
                  @click="() => fileInputRef.click()"
                >
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.mp4"
                    class="d-none"
                    ref="fileInputRef"
                    @change="handleFileUpload"
                  />
                  Choose File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--file preview-->
      <div
        v-if="state.form.image.filePreview"
        class="col-12"
      >
        <div class="preview d-flex justify-content-center mt-2">
          <div class="card">
            <div class="card-img-top user-uploaded-image">
              <img
                class="img-fluid user-image selected-file-preview"
                :src="state.form.image.filePreview"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <!--top bg color-->
      <div class="form-group col-6">
        <label for="top-color" class="col-form-label">
          Top BG Color:
          <span class="text-danger">*</span>
          <form-input-help>Background color of image area</form-input-help>
        </label>
        <input
          type="color"
          class="form-control color-input"
          id="top-color"
          placeholder="Select Top Color"
          v-model.lazy="state.form.topBgColor"
        >
      </div>

      <!--bottom color-->
      <div class="form-group col-6">
        <label for="bottom-color" class="col-form-label">
          Bottom BG Color:
          <span class="text-danger">*</span>
          <form-input-help>Background color of button area</form-input-help>
        </label>
        <input
          type="color"
          class="form-control color-input"
          id="bottom-color"
          placeholder="Select Top Color"
          v-model.lazy="state.form.bottomBgColor"
        >
      </div>

      <!--title-->
      <div class="form-group col-8">
        <label for="title" class="col-form-label">
          Title:
          <form-input-help>Will show below image</form-input-help>
        </label>
        <input
          type="text"
          class="form-control"
          id="title"
          placeholder="Promo Title"
          v-model.trim="state.form.title.value"
          autocomplete="off"
        >
        <FormErrorMsg :error="state.form.title.error" />
      </div>

      <!--title color-->
      <div class="form-group col-4">
        <label for="title-color" class="col-form-label">
          Title Color:
          <form-input-help>Color of title text</form-input-help>
        </label>
        <input
          type="color"
          class="form-control color-input"
          id="title-color"
          placeholder="Select Top Color"
          v-model.trim="state.form.titleColor"
        >
      </div>

      <!--sub title-->
      <div class="form-group col-12">
        <label for="sub-title" class="col-form-label">
          Sub Title:
          <form-input-help>Will show below title</form-input-help>
        </label>
        <input
          type="text"
          class="form-control"
          id="sub-title"
          placeholder="Promo Sub Title"
          v-model.trim="state.form.subTitle.value"
          autocomplete="off"
        >
        <FormErrorMsg :error="state.form.subTitle.error" />
      </div>

      <!--show extra button-->
      <div
        :class="`form-group col-${state.form.btnShow ? 4 : 6}`"
      >
        <label class="col-form-label">
          Action Button:
          <form-input-help>Shows click to action button</form-input-help>
        </label>
        <div>
          <SwitchComp
            :checked="state.form.btnShow"
            @change="val=>state.form.btnShow=val"
            enable-text="Shown"
            disable-text="Hidden"
          />
        </div>
      </div>

      <!--button text-->
      <FadeSlideAnimation duration="2">
        <div
          v-if="state.form.btnShow"
          class="form-group col-4"
        >
          <label for="btn-txt" class="col-form-label">
            Button Title:
            <span class="text-danger">*</span>
            <form-input-help>Action button text</form-input-help>
          </label>
          <input
            type="text"
            class="form-control"
            id="btn-txt"
            placeholder="Button Title"
            v-model.trim="state.form.btnText.value"
            autocomplete="off"
          />
          <FormErrorMsg :error="state.form.btnText.error" />
        </div>
      </FadeSlideAnimation>

      <!--button text color-->
      <FadeSlideAnimation duration="2">
        <div
          v-if="state.form.btnShow"
          class="form-group col-4"
        >
          <label for="btn-title-color" class="col-form-label">
            Btn Title Color:
            <span class="text-danger">*</span>
            <form-input-help>Color of button title</form-input-help>
          </label>
          <input
            type="color"
            class="form-control color-input"
            id="btn-title-color"
            placeholder="Select Button Text Color"
            v-model.lazy="state.form.btnTextColor"
          >
        </div>
      </FadeSlideAnimation>

      <!--button link-->
      <FadeSlideAnimation duration="2">
        <div
          v-if="state.form.btnShow"
          class="form-group col-8"
        >
          <label for="btn-link" class="col-form-label">
            Button Link:
            <span class="text-danger">*</span>
            <form-input-help>ex: <strong>/order</strong> for order page</form-input-help>
          </label>
          <input
            type="text"
            class="form-control"
            id="btn-link"
            placeholder="Action Link"
            v-model.trim="state.form.btnLink"
            autocomplete="off"
          >
        </div>
      </FadeSlideAnimation>

      <!--status-->
      <div
        :class="`form-group col-${state.form.btnShow ? 4 : 6}`"
      >
        <label class="col-form-label">
          Status:
          <form-input-help> Disabling will make this unusable</form-input-help>
        </label>
        <div>
          <SwitchComp
            :checked="state.form.status"
            @change="val=>state.form.status=val"
            enable-text="Enabled"
            disable-text="Disabled"
          />
        </div>
      </div>
    </div>
  </ModalComp>
</template>

<script>
import ModalComp from '@/components/Util/ModalComp';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import FormInputHelp from '@/components/Util/FormInputHelp';
import SwitchComp from '@/components/Util/SwitchComp';
import { useStore } from 'vuex';
import Toaster from '@/utils/Toaster';
import { maxLength, required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import ErrorHelper from '@/utils/ErrorHelper';
import { STORAGE_FULL_URL } from '@/utils/Urls';
import FormErrorMsg from '@/components/Util/FormErrorMsg';
import FadeSlideAnimation from '@/components/Util/Animations/FadeSlideAnimation';

export default defineComponent({
  name: 'PromotionalOfferAction',
  emits: ['updated', 'update:show'],
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  components: { FadeSlideAnimation, FormErrorMsg, SwitchComp, FormInputHelp, ModalComp },

  setup (props, { emit }) {

    const store = useStore();

    const settings = computed(() => {
      return store.getters['setting/getSettings']([
        'promotional_slider_enable',
        'promotional_img_1',
        'promotional_title_1',
        'promotional_subtitle_1',
        'promotional_img_background_color',
        'promotional_title_color',
        'promotional_btn_text_color',
        'promotional_btn_background_color',
        'promotional_extra_btn_show',
        'promotional_extra_btn_title',
        'promotional_extra_btn_link'
      ]);
    });

    const state = reactive({
      showAction: false,

      form: {
        image: {
          value: '',
          error: false,
          maxAllowedSize: 1500,
          fileName: '',
          filePreview: '',
        },
        topBgColor: '#ffffff',
        bottomBgColor: '#000000',
        title: {
          value: '',
          error: false
        },
        titleColor: '#000000',
        subTitle: {
          value: '',
          error: false
        },
        btnShow: true,
        btnText: {
          value: 'Order Now',
          error: false
        },
        btnTextColor: '#000000',
        btnLink: '/order',
        status: true,
      },

      formSubmitting: false
    });

    const fileInputRef = ref(null);

    const settingAssetUrl = `${STORAGE_FULL_URL}settings/`;

    // validation start
    const validationRules = {
      form: {
        image: {
          value: {
            required,
          }
        },
        title: {
          value: {
            maxLength: maxLength(30)
          }
        },
        subTitle: {
          value: {
            maxLength: maxLength(50)
          }
        },
        btnText: {
          value: {
            requiredIf: requiredIf(state.form.btnShow),
            maxLength: maxLength(25)
          }
        }
      }
    };

    const validator = useVuelidate(validationRules, state, { $autoDirty: true });

    watch(validator, () => {
      // map errors
      ErrorHelper.getValidationErrors(validator, (errors) => {
          state.form.image.error = errors['form.image.value'] || false;
          state.form.title.error = errors['form.title.value'] || false;
          state.form.subTitle.error = errors['form.subTitle.value'] || false;
          state.form.btnText.error = errors['form.btnText.value'] || false;
        },
        {
          'form.image.value': 'Image',
          'form.title.value': 'Title',
          'form.subTitle.value': 'Sub Title',
          'form.btnText.value': 'Button Title',
        }
      );

    });

    // validation end

    function resetForm () {
      state.form = {
        image: {
          value: '',
          error: false,
          maxAllowedSize: 1500,
          fileName: '',
          filePreview: '',
        },
        topBgColor: '#ffffff',
        bottomBgColor: '#000000',
        title: {
          value: '',
          error: false
        },
        titleColor: '#000000',
        subTitle: {
          value: '',
          error: false
        },
        btnShow: true,
        btnText: {
          value: 'Order Now',
          error: false
        },
        btnTextColor: '#000000',
        btnLink: '/order',
        status: true,
      };
    }

    const handleDataSave = () => {

      const data = {
        promotional_slider_enable: state.form.status ? 1 : 0,
        promotional_img_1: state.form.image.value || state.form.image.fileName,
        promotional_title_1: state.form.title.value,
        promotional_title_color: state.form.titleColor,
        promotional_subtitle_1: state.form.subTitle.value,
        promotional_img_background_color: state.form.topBgColor,
        promotional_btn_background_color: state.form.bottomBgColor,
        promotional_extra_btn_show: state.form.btnShow ? 1 : 0,
        promotional_extra_btn_title: state.form.btnText.value,
        promotional_btn_text_color: state.form.btnTextColor,
        promotional_extra_btn_link: state.form.btnLink
      };

      updateSetting(data);
    };

    const updateSetting = async (data) => {

      state.formSubmitting = true;

      try {

        let response = await store.dispatch('setting/updatePromotionalBannerSettings', data);

        Toaster.successAlt({
          message: response.data.message || 'Promotional Banner Updated'
        });

        fireEvent('updated');

      } catch (e) {

        Toaster.error({
          message: e.message || 'Something went wrong. try again'
        });

        ErrorHelper.mapServerError(e, (err, ex) => {
          state.form.image.error = ex(err.promotional_img_1);
          state.form.title.error = ex(err.promotional_title_1);
          state.form.subTitle.error = ex(err.promotional_subtitle_1);
          state.form.btnText.error = ex(err.promotional_extra_btn_title);
        });

      }

      state.formSubmitting = false;
    };

    const fireEvent = (event) => emit(event);

    const handleToggle = (hide = false) => {
      state.showAction = hide;
    };

    const handleFileUpload = () => {
      const selectedFile = fileInputRef.value.files[0];

      state.form.image.value = selectedFile;

      const previewUrl = URL.createObjectURL(selectedFile);

      state.form.image.filePreview = previewUrl;
    };

    function mapSettingsToForm (settings) {

      state.form.status = settings.promotional_slider_enable;

      state.form.image.fileName = settings.promotional_img_1;
      state.form.image.filePreview = settingAssetUrl + settings.promotional_img_1;

      state.form.topBgColor = settings.promotional_img_background_color;
      state.form.bottomBgColor = settings.promotional_btn_background_color;

      state.form.title.value = settings.promotional_title_1;
      state.form.titleColor = settings.promotional_title_color;

      state.form.subTitle.value = settings.promotional_subtitle_1;

      state.form.btnShow = settings.promotional_extra_btn_show;
      state.form.btnText.value = settings.promotional_extra_btn_title;
      state.form.btnLink = settings.promotional_extra_btn_link;
      state.form.btnTextColor = settings.promotional_btn_text_color;
    }

    watch(() => props.show, (nv) => {
      state.showAction = nv;

      // reset form when closing
      if (!nv) {
        resetForm();
        return;
      }

      // map settings again
      mapSettingsToForm(settings.value);
    });

    watch(() => state.showAction, (nv) => {
      emit('update:show', nv);
    });

    watch(() => settings.value, (settings) => {
      // map with setting
      mapSettingsToForm(settings);
    }, { immediate: true });

    return {
      state,
      fileInputRef,
      handleDataSave,
      handleToggle,
      handleFileUpload,
    };
  },
});
</script>

<style scoped lang="scss">
input[readonly] {
  outline: none;
  background-color: transparent;
}

.selected-file-preview {
  max-height: 150px !important;
}

.color-input {
  height: 35px;
  padding: 0;
  border: 0 transparent;
}
</style>
